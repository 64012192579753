import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const currencyMylub = () => {
  return (
    <Layout>
      <SEO
        title="Coins, GP and eFootball Points (PES myClub Currency)"
        description="Learn how to use GP, Coins and eFootball Points in PES 2021 myClub to sign new players or managers and buy trainers or kits."
      />
      <div className="container">
        <h1>Coins, GP and eFootball Points (PES myClub Currency)</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Coins, GP and eFootball Points</li>
        </ul>
        <p>
          Just like in real life, you’ll need money to run your club. Signing
          new players or managers can be expensive, and renewing their contracts
          is something you’ll also have to keep in mind.
        </p>
        <p>
          You will have to rely mostly on GP and Coins for this. eFootball
          Points is new in 2021 and can also get you some players.
        </p>
        <h2>GP</h2>
        <p>
          GP is probably the currency most will go for. In the beginning, pretty
          much everything you do in the game gives you GP - simple things, like
          a new feint or using a different tactic will give you 100-200 GP.
        </p>
        <p>
          Every time you complete a game in myClub you will also earn some GP -
          even if you lose.
        </p>
        <p>
          You can use this to renew contracts, hire managers, bid for scouts to
          get the players you want or try your luck with an agent. You won’t be
          able to get weekly Featured Players with GP, and most top Managers
          will be locked (ie, available with coins only) when you’re just
          getting started, so keep that in mind.
        </p>
        <p>
          <Link to="/how-to-get-more-gp/">
            Have a look at this section to learn the most effective ways to
            quickly gain GP.
          </Link>
        </p>
        <h2>Coins</h2>
        <p>
          Coins are harder to obtain, especially after completing the initial
          achievements. Spending coins is the only way you can sign weekly
          Featured Players and the way to go if you want to unlock top Managers
          early on.
        </p>
        <p>
          You get 30/40 coins by logging in to the game from time to time (every
          3 days), and sometimes PES will simply give you coins as a reward for
          participating in a competition or when a product launch (such as PES
          Lite or PES Mobile) occurs.
        </p>
        <p>
          Obtaining coins takes a while, but there is one way to speed up the
          process - you pay real money for coins. That’s how Konami makes money,
          after all!
        </p>
        <p>100 coins are roughly $1 USD/EUR.</p>
        <h2>eFootball Points</h2>
        <p>
          This is a new addition in PES 2021, and enables you to earn points in
          a variety of ways across all platforms. This means you can play PES
          Mobile throughout the day and then use your points in your PlayStation
          or Xbox console.
        </p>
        <p>
          To benefit from this new currency, you will need to link your game
          account to your KONAMI ID. Your Konami ID can only be linked to a
          single account on each platform,{" "}
          <a
            href="https://www.konami.com/wepes/efootball_point/en/"
            target="_blank"
          >
            as stated here
          </a>
          . It should be fine for most cases.
        </p>
        <p>
          To use those points, you should go to the following screen:
          <ul>
            <li>
              eFootball PES 2021 Season Update (PS4, Xbox One, Steam): inside
              myClub homescreen, go to MYCLUB STORE > EFOOTBALL POINTS;
            </li>
            <li>
              PES Mobile (eFootball PES 2021) (iOS, Android): Tap the shopping
              cart icon in the upper and go to REDEEM POINTS > [Details].
            </li>
          </ul>
        </p>
        <p>
          You can get some great players, including legends, as well as some
          rare club Kits (usually 2.000 points each) or Skill/Position trainers
          (500 points each).
        </p>
        <p>
          Please note that these points have an expiration date. According to
          Konami, they expire at 23:59:59 (UTC) on the final day of the month,
          six-months after you claim them from your Inbox. You can view such
          details in the previously mentioned screens, or by logging in{" "}
          <a href="https://my.konami.net/index.html" target="_blank">
            here
          </a>
          ).
        </p>
      </div>
    </Layout>
  )
}

export default currencyMylub
